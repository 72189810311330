import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import meme from "../images/blog2/meme.webp";
import logo from "../images/blog2/mylogo.webp";
import evangelion from "../images/blog2/evangelion.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Logos & Egos" keywords={['gatsby', 'application', 'react', 'robots']} mdxType="SEO" />
    <h1>{`Logos & Egos`}</h1>
    <h2>{`May 17, 2021`}</h2>
    <p>{`Design isn’t creative as people think it is. Art in general isn’t creative as people think it is. The further music strays away from human speech patterns, the less it sounds like music and the more it sounds like noise. There are rules to contain creativity. `}</p>
    <p>{`Design is usually a balance of creativity and critical thinking. Sometimes, good design can be just 100% critical thinking. I don't get to be creative as often as I like to, so I capitalize on it when I get the chance.`}</p>
    <p>{`I needed a logo for my future company. Creating a company can be a miserable experience, so I wanted a logo to marvel at when everything is trash. It’ll remind me of my (almost unattainable) hopes and dreams.`}</p>
    <p>{`The funny thing about logos is that it’s usually the first thing people make when they care more about feeling good than doing the actual work. And then the project promptly gets abandoned with the logo being the only proof of its existence. `}</p>
    <p>{`Thankfully, in this case, I don’t feel as vapid creating the logo before the company even exists. I’ve already spent over a year making some technological progress on synthetic muscles for my robots.`}</p>
    <p>{`This whole idea of creating robots is absurd and fun. So I made the logo absurd and fun. My logo is a biblically accurate angel with a copyright symbol as an eye. `}</p>
    <p>{`The bible describes seraphim as beings made of wheels within wheels, with many eyes embedded on these wheels, and with many pairs of wings covering these wheels. Also, the angels are on fire... or something like that.`}</p>
    <img height="auto" loading="lazy" src={meme} alt="My logo." className="center" />
    <div className="caption"> I wonder what those people in the Bible saw... Did a gyroscopic drone get sent to the past?</div>
    <img height="auto" loading="lazy" src={logo} alt="My logo." className="center" />
    <div className="caption">Here's my logo!</div>
    <p>{`Religion and capitalism. That’s so American. I love it. `}</p>
    <p>{`The protrusions around the circle are meant to be wings/fire/rays/eyes. Yes, it’s meant to be all those things. It’s abstractish(?)!`}</p>
    <p>{`The ovals and circle are meant to be the wheels that angels are made of. I tried to make the letter A (for American Robotics Company) out of the overlapping wheels, but it’s not that obvious.`}</p>
    <p>{`The wheels are hollow, so you should be able to see through them. But for some reason, part of a wheel is hidden behind the hollow part of another. It doesn’t make sense, but it eliminates perfect symmetry. Symmetry is nice, but perfect symmetry is suspicious. It also had the added bonus of giving the logo an angle, as if though it’s floating above and looking down on you.`}</p>
    <p>{`I hate how tiny copyright symbols show up next to logos. It's like having a chaperone at a prom. Just involve them! So I put mine center-stage. There’s a sort of honesty to it. Like you can see the truth through the illusion.`}</p>
    <p>{`Alright so why an angel? I should have explained that first right? I just like grand mysterious other-worldly concepts. I'm not religious at all, but religion is so important to humanity. There's also a more vain reason.`}</p>
    <p>{`My best friend Theo likes to call me Gendo. Gendo Ikari is a character from the amazing anime, Neon Genesis Evangelion.`}</p>
    <p>{`Gendo is a scientist that captures God’s angels and researches them to create his own more useful angels. It’s kinda like how I’m taking god’s design of humans and iterating on it to create robots.`}</p>
    <img height="auto" loading="lazy" src={evangelion} alt="A drawing of an angel from Neon Genesis Evangelion." className="center" />
    <div className="caption">In the anime, Gendo has captured the angel, Lilith.</div>
    <p>{`You might be thinking “Wow, your head is up somewhere”. But that's the point. Satoru Iwata, the former CEO of Nintendo, said "Creativity is an expression of ego".`}</p>
    <p>{`When Travis Scott says "1, 2, 3, 4, 5, that's the countdown till I slide", he's not actually going to slide you. Once he leaves the studio, he probably goes home with a happy meal and lounges around on the couch with his daughter. I'm not Travis Scott but my life is mostly mundane as well. The display of ego is just a flash in a dim rainstorm.`}</p>
    <p>{`When we don't accept our own egos, we see artistic expressions of egos as cringe. When we accept all parts of ourselves, those expressions reveal something else. When I present my ego to you, I accept that my value hinges on superficial things. I accept that I'm a fragile person sometimes. It's just human.`}</p>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      